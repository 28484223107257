import styles from '../styles/PlayerRatingsTable.module.css'
import profileStyles from "../styles/PlayerProfile.module.css";
import React from "react";
import {Link} from "react-router-dom";


function PlayerRatingsTable({ playerRatings, season }) {
    if (!playerRatings || Object.keys(playerRatings).length === 0) {
        return <div>No data available</div>;
    }

    const orderedPlayerRatings = Object.entries(playerRatings.player_ratings).sort(
        ([idA, playerA], [idB, playerB]) => playerB.rating - playerA.rating // Example: sort by rating descending
    );
    const ratingDenom = playerRatings.rating_denom;

    return (
        <div className={styles.graphContainer}>
            {/*{Object.entries(playerRatings.player_ratings).map(([playerId, playerData], index) => (*/}
            {orderedPlayerRatings.map(([playerId, playerData], index) => (
                playerData.rating !== undefined && (
                    <div key={playerId} className={styles.playerRow}>
                        {/* Player Name on the left side */}
                        <Link
                            to={`/players/id/${playerId}`}
                            className={styles.playerName}
                            style={{
                              '--primary-color': playerData.primary_color,
                              '--hover-color': playerData.secondary_color || '#0097b3', // Default hover color if none provided
                            }}
                        >
                            {playerData.name}
                        </Link>

                        {/* Rating Bar */}
                        <div className={styles.ratingBarContainer}>
                            <div
                                className={styles.ratingBarFill}
                                style={{
                                    width: `${(playerData.rating / ratingDenom) * 100}%`,
                                    '--primary-color': playerData.primary_color,
                                    '--hover-color': playerData.secondary_color || '#0097b3', // Default hover color if none provided
                            }}
                            >
                                {/* Rating inside the bar */}
                                <span className={styles.ratingBarLabel}>
                                    {playerData.rating}
                                </span>
                            </div>
                        </div>

                        {/* Player Image on the right */}
                        <Link to={`/players/id/${playerId}`} key={playerId}>
                            <img
                                className={styles.mug}
                                src={`https://assets.nhle.com/mugs/nhl/${season}/${playerData.team_abb}/${playerId}.png`}
                                alt={`${playerData.name}`}
                            />
                        </Link>
                    </div>
                )
            )
            )}
        </div>
    );
}

export default PlayerRatingsTable;
