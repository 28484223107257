import React, {useEffect, useLayoutEffect, useState} from "react";
import styles from '../styles/TeamHomePage.module.css'
import {Link, useParams} from "react-router-dom";
import SeasonDropdown from "../components/SeasonDropdown";
import PlayerRatingsTable from "../components/PlayerRatingsTable";


function updateBar(id, value, primaryColor) {
    const inputValue = parseFloat(value);

    // if (inputValue < -1 || inputValue > 1) {
    //     console.error("Value is out of the allowed range (-0.5 to 0.5)");
    //     return;
    // }

    const barFill = document.getElementById(id);
    if (!barFill) return;  // Ensure the element exists

    const maxValue = 1;
    const percentage = (Math.abs(inputValue) / maxValue) * 50;

    barFill.style.backgroundColor = primaryColor;

    // const ratingBars = document.getElementsByName('ratingBar');
    // ratingBars.forEach(ratingBar => {
    //     ratingBar.style.color = primaryColor
    // });

    // const minTextWidth = 30; // Minimum width to fit the text comfortably
    // const barWidth = barFill.offsetWidth; // Get the actual bar width after rendering
    //
    // // Set the width and position based on value
    // if (inputValue >= 0) {
    //     barFill.style.width = `${percentage}%`;
    //     barFill.style.left = '50%';
    //     barFill.classList.remove(styles.negative);
    //     barFill.classList.add(styles.positive);
    //     const xgString = `+ ${inputValue}`
    //
    //     if (barWidth < minTextWidth) {
    //         console.log('barWidth:', barWidth, ' minTextWidth: ', minTextWidth)
    //         barFill.innerHTML = `<span class="barLabel" style="color: ${primaryColor}; position: absolute; left: calc(100% + 5px); white-space: nowrap; width: 70px;">${xgString}</span>`;
    //     } else {
    //         console.log('barWidth:', barWidth, ' minTextWidth: ', minTextWidth)
    //         barFill.innerHTML = `<span class="barLabel" style="color: #eeeeee};">${xgString}</span>`;
    //     }
    //
    //     // barFill.innerHTML = `<span class="barLabel" style="color: ${secondaryColor};">${xgString}</span>`;
    // } else {
    //     barFill.style.width = `${percentage}%`;
    //     barFill.style.left = `calc(50% - ${percentage}%)`;
    //     barFill.classList.remove(styles.positive);
    //     barFill.classList.add(styles.negative);
    //     const xgString = `${inputValue}`
    //
    //     if (barWidth < minTextWidth) {
    //         barFill.innerHTML = `<span class="barLabel" style="color: ${primaryColor}; position: absolute; right: calc(100% + 5px); white-space: nowrap; width: 70px;">${xgString}</span>`;
    //     } else {
    //         barFill.innerHTML = `<span class="barLabel" style="color: #eeeeee};">${xgString}</span>`;
    //     }

        // barFill.innerHTML = `<span class="barLabel" style="color: ${secondaryColor}; ">${xgString}</span>`;
// }
    const observer = new MutationObserver(() => {
        const minTextWidth = 30;
        const barWidth = barFill.getBoundingClientRect().width;

        if (inputValue >= 0) {
            barFill.style.width = `${percentage}%`;
            barFill.style.left = '50%';
            barFill.classList.remove(styles.negative);
            barFill.classList.add(styles.positive);
            const xgString = `+ ${inputValue}`;

            if (barWidth < minTextWidth) {
                barFill.innerHTML = `<span class="barLabel" style="color: ${primaryColor}; position: absolute; left: calc(100% + 5px); white-space: nowrap; width: 70px;">${xgString}</span>`;
            } else {
                barFill.innerHTML = `<span class="barLabel" style="color: #eeeeee};">${xgString}</span>`;
            }
        } else {
            barFill.style.width = `${percentage}%`;
            barFill.style.left = `calc(50% - ${percentage}%)`;
            barFill.classList.remove(styles.positive);
            barFill.classList.add(styles.negative);
            const xgString = `${inputValue}`;

            if (barWidth < minTextWidth) {
                barFill.innerHTML = `<span class="barLabel" style="color: ${primaryColor}; position: absolute; right: calc(100% + 5px); white-space: nowrap; width: 70px;">${xgString}</span>`;
            } else {
                barFill.innerHTML = `<span class="barLabel" style="color: #eeeeee};">${xgString}</span>`;
            }
        }

        // Disconnect the observer after the initial change
        observer.disconnect();
    });

    // Observe changes to the barFill's attributes, specifically width changes
    observer.observe(barFill, { attributes: true });

    // Trigger the initial width change
    requestAnimationFrame(() => {
        barFill.style.width = `${percentage}%`;
    });
}

const PerformanceBar = ({ title, barId, loading }) => {
    if (loading) return null;
    return (
        <>
            <h2 className={styles.barTitle}>{title}</h2>
            <div className={styles.barContainer}>
                <div className={styles.barBackground}>
                    <div className={styles.barFill} id={barId}></div>
                    <div className={styles.zeroLine}></div>
                </div>
            </div>
        </>
    );
};

function TeamHomePage() {
    const { teamAbb } = useParams();
    const [loading, setLoading] = useState(true);
    const [teamData, setTeamData] = useState([]);
    const [teamInfo, setTeamInfo] = useState([]);
    const [playerData, setPlayerData] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState('20242025');  // Default season

    // Handle the selected season
    const handleSeasonChange = (season) => {
        setSelectedSeason(season);
    };

    // Fetch data when component mounts or when teamAbb or selectedSeason change
    useEffect(() => {
        setLoading(true);
        getData(selectedSeason)
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }, [teamAbb, selectedSeason]); // Re-fetch data when teamAbb or selectedSeason change

    const getData = async (season) => {
        try {
            // Fetch team data
            let apiUrl = `https://muffinhockey.com/api/team/${teamAbb}/${season}/data`;
            const teamDataResp = await fetch(apiUrl);
            const teamDataJson = await teamDataResp.json();
            setTeamData(teamDataJson);

            // Fetch team info
            apiUrl = `https://muffinhockey.com/api/teams/${teamAbb}`;
            const teamInfoResp = await fetch(apiUrl);
            const teamInfoJson = await teamInfoResp.json();
            setTeamInfo(teamInfoJson);

            // Fetch player data
            apiUrl = `https://muffinhockey.com/api/team/${teamAbb}/${season}/player-season-ratings`;
            const playerDataResp = await fetch(apiUrl);
            const playerDataJson = await playerDataResp.json();
            setPlayerData(playerDataJson);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

     useEffect(() => {
        if (!loading && teamInfo.primary_color) {
            const teamName = document.getElementById('teamName');
            const teamRecord = document.getElementById('teamRecord');
            if (teamName && teamRecord) {
                teamName.style.color = teamInfo.primary_color;
                teamRecord.style.color = teamInfo.primary_color;
            }
        }
    }, [teamInfo, loading]);  // Trigger this effect when teamInfo changes

    useLayoutEffect(() => {
        if (!loading && teamData.xg_scored_above_average && teamInfo.primary_color) {
            updateBar('xgFor', teamData.xg_scored_above_average, teamInfo.primary_color);
        }
        if (!loading && teamData.xg_scored_above_average && teamInfo.primary_color) {
            updateBar('gFor', teamData.goals_scored_above_average, teamInfo.primary_color);
        }
        if (!loading && teamData.xg_scored_above_average && teamInfo.primary_color) {
            updateBar('xgAg', teamData.xg_allowed_above_average, teamInfo.primary_color);
        }
        if (!loading && teamData.xg_scored_above_average && teamInfo.primary_color) {
            updateBar('gAg', teamData.goals_allowed_above_average, teamInfo.primary_color);
        }
    }, [loading, teamData, teamInfo]);  // Update bar after loading completes

    // return (
    //     <div className={styles.pageContainer}>
    //         {!loading && (
    //             <h1 className={styles.teamHeading} id='teamName'>
    //                 <img className={styles.teamLogo}
    //                      src={`https://assets.nhle.com/logos/nhl/svg/${teamAbb}_light.svg`}
    //                      alt={`${teamAbb} logo`}
    //                 />
    //                 {teamInfo.location + ' ' + teamInfo.name}
    //             </h1>
    //         )
    //         }
    //         <div className={styles.buttonContainer}>
    //             <Link key="roster" className={styles.button} to={`/teams/${teamAbb}/roster`}>
    //                 <h1>Player Cards</h1>
    //             </Link>
    //             <Link key="games" className={styles.button} to={`/teams/${teamAbb}/games`}>
    //                 {/*/>*/}
    //                 <h1>Game Stats</h1>
    //             </Link>
    //         </div>
    //         <SeasonDropdown onSeasonChange={handleSeasonChange}/>
    //         {!loading && (
    //             <h2 className={styles.barTitle}>Team Performance vs. Average</h2>
    //             <div className={styles.barContainer}>
    //                 <div className={styles.barBackground}>
    //                     <div className={styles.barFill} id="barFill"></div>
    //                     <div className={styles.zeroLine}></div>
    //                 </div>
    //             </div>
    //         )}
    //     </div>
    // )
    // Render loading spinner until everything is ready
    if (loading) {
        return (
            <div  className={styles.pageContainer}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
       return (
        <div className={styles.pageContainer}>
            {loading ? (
                <div className={styles.spinnerContainer}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    {/* Team Header */}
                    <h1 className={styles.teamHeading} id="teamName">
                        <img
                            className={styles.teamLogo}
                            src={`https://assets.nhle.com/logos/nhl/svg/${teamAbb}_light.svg`}
                            alt={`${teamAbb} logo`}
                        />
                        {`${teamInfo.location ?? ''} ${teamInfo.name ?? ''}`}
                    </h1>

                    {/* Navigation Buttons */}
                    <div className={styles.buttonContainer}>
                        <Link key="roster" className={styles.button} to={`/teams/${teamAbb}/roster`}>
                            <h1>Player Cards</h1>
                        </Link>
                        <Link key="games" className={styles.button} to={`/teams/${teamAbb}/games`}>
                            <h1>Game Stats</h1>
                        </Link>
                    </div>

                    {/* Season Dropdown */}
                    <SeasonDropdown selectedSeason={selectedSeason} onSeasonChange={handleSeasonChange} />

            {/* Team Record */}
                    <h1 className={styles.teamRecord} id="teamRecord">{teamData.record}</h1>

                    {/* Performance Bars */}
                    <PerformanceBar
                        title="Goals per Game vs. Average"
                        barId="gFor"
                        loading={loading}
                    />
                    <PerformanceBar
                        title="xG per Game vs. Average"
                        barId="xgFor"
                        loading={loading}
                    />
                    <PerformanceBar
                        title="Goals Against per Game vs. Average"
                        barId="gAg"
                        loading={loading}
                    />
                    <PerformanceBar
                        title="xG Against per Game vs. Average"
                        barId="xgAg"
                        loading={loading}
                    />

                    {/* Player Ratings */}
                    <h1 className={styles.playerRatingsHeader}>Average Player Ratings</h1>
                    {playerData && (
                        <PlayerRatingsTable playerRatings={playerData} season={selectedSeason} />
                    )}
                </>
            )}
        </div>
    );
}

export default TeamHomePage