import {Link} from "react-router-dom";
import styles from "../styles/TeamSelectionPage.module.css";
import React from "react";

export function RenderTeamList(teams) {
    return teams.map(team => (
        // <Link className={styles.teamRows} to={`/teams/${team.abb}`} >
        <Link key={team.abb} className={styles.teamRows} to={`/teams/${team.abb}/`}>
            <li className="list-group-item list-group-item-action"
                key={team.id}
            >
                <span className="me-10">
                    <img className={styles.teamLogo}
                         src={`https://assets.nhle.com/logos/nhl/svg/${team.abb}_light.svg`}
                         alt={`${team.abb} logo`}/>
                </span>
                <span>
                    {'    ' + team.location + ' ' + team.name}
                </span>
            </li>
        </Link>
    ));
}