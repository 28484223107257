import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import styles from "../styles/GamePage.module.css";
import LZString from 'lz-string';
import ColorThief from 'colorthief';

// function adjustXCoordinate(x) {
//     const absX = Math.abs(x);
//     let adjustedX = x;
//
//     if (absX < 57) {
//         // Increase the absolute value proportionally based on distance from 57
//         // Make the adjustment slightly more aggressive to move x=25 closer to x=27
//         adjustedX = x < 0 ? x - (5 * (57 - absX) / 57) : x + (5 * (57 - absX) / 57);
//     } else if (absX > 60) {
//         // Decrease the absolute value proportionally based on distance from 60
//         // Make the adjustment slightly more aggressive to move x=89 closer to x=87
//         adjustedX = x < 0 ? x + (5 * (absX - 60) / (100 - 60)) : x - (5 * (absX - 60) / (100 - 60));
//     }
//
//     return adjustedX;
// }
function adjustXCoordinate(x) {
    const absX = Math.abs(x);
    let adjustedX = x;

    if (absX < 57) {
        // Increase the absolute value proportionally based on distance from 57
        adjustedX = x < 0 ? x - (5 * (57 - absX) / 57) : x + (5 * (57 - absX) / 57);
    } else if (absX > 60) {
        // Decrease the absolute value proportionally based on distance from 60
        adjustedX = x < 0 ? x + (5 * (absX - 60) / (100 - 60)) : x - (5 * (absX - 60) / (100 - 60));
    }

    // Increase the absolute value of the coordinate by 6
    adjustedX = adjustedX + 5;

    return adjustedX;
}


function mapCoordinateToPixel(x, y) {
    // const rinkWidth = 498; // From the pixel bounds you previously provided
    // const rinkHeight = 190; // From the pixel bounds you previously provided
    // const rinkWidth = 900 * 0.83; 834 - 93
    // const rinkHeight = 540 * 0.527777; 763 - 427
    const rinkWidth = 834 - 93
    const rinkHeight = 763 - 427

    // const rinkXCenter = 293; // X-coordinate for the center
    // const rinkYCenter = 173; // Y-coordinate for the center
    const rinkXCenter = 422; // X-coordinate for the center
    const rinkYCenter = 255; // Y-coordinate for the center
    // const rinkXCenter = 900 * 0.33;  // Adjust based on center relative to container width
    // const rinkYCenter = 540 * 0.32; // Adjust based on center

    const xRange = 200; // 100 - (-100)
    const yRange = 84;  // 42 - (-42)

    const xScale = rinkWidth / xRange;
    const yScale = rinkHeight / yRange;

    const adjustedX = adjustXCoordinate(x);
    // const adjustedX = x

    const pixelX = rinkXCenter + adjustedX * xScale;
    const pixelY = rinkYCenter - y * yScale; // Subtract y to maintain the correct direction
    return { pixelX, pixelY };
}

function plotShots(shotData, winner) {
    const container = document.getElementById('rinkContainer');
    // Check if the container exists
    if (!container) {
        // console.error('Rink container not found');
        return
    }
    if (!Array.isArray(shotData)) {
        // console.error('Shot data is undefined or not an array');
        return;
    }
    // const { pixelX, pixelY } = mapCoordinateToPixel(25, 0);
    // const shotElement = document.createElement('div');
    // shotElement.classList.add(`${styles.winnerGoal}`);
    // shotElement.style.left = `${pixelX}px`;  // Subtract half the width to center
    // shotElement.style.top = `${pixelY}px`;   // Subtract half the height to center
    // container.appendChild(shotElement);

    // console.log(shotData)
    shotData.forEach(shot => {
        const { x, y } = shot;  // Access x and y from the dictionary
        const { pixelX, pixelY } = mapCoordinateToPixel(x, y);
        const shotElement = document.createElement('div');
        // console.log(className)
        if (winner === true) {
            if (shot.goal === 1) {
                // console.log(`goal - ${shot.shooter_name}`)
                shotElement.classList.add(`${styles.winnerGoal}`);
            } else {
                shotElement.classList.add(`${styles.winnerShot}`);
            }
        } else {
            if (shot.goal === 1) {
                // console.log(`goal - ${shot.shooter_name}`)
                shotElement.classList.add(`${styles.loserGoal}`);
            } else {
                shotElement.classList.add(`${styles.loserShot}`);
            }
        }
        shotElement.style.left = `${pixelX}px`;  // Position the element based on pixelX
        shotElement.style.top = `${pixelY}px`;   // Position the element based on pixelY


        // Create the tooltip element
        const tooltip = document.createElement('div');
        tooltip.classList.add(`${styles.tooltip}`);

        // Add text info to the tooltip
        const text = document.createElement('p');
        if (shot.goal === 1) {
            text.innerHTML = `<em>${shot.strength_str} Goal!</em><br>${shot.shot_type}<br>${shot.xg_value} xG<br>Scored by <strong>${shot.shooter_name}</strong><br>${shot.assist_str}<br>${shot.time_remaining} remaining in period ${shot.period}`
            tooltip.style.backgroundColor = 'pink'
        } else {
            if (shot.sog === 1) {
                text.innerHTML = `<strong>${shot.xg_value} xG</strong><br>Shot on goal by ${shot.shooter_name}<br>${shot.time_remaining} remaining in period ${shot.period}`

            } else {
                text.innerHTML = `<strong>${shot.xg_value} xG</strong><br>Shot attempt by ${shot.shooter_name}<br>${shot.time_remaining} remaining in period ${shot.period}`
            }
        }
        // Add image to the tooltip
        const img = document.createElement('img');
        img.src = `https://assets.nhle.com/mugs/nhl/${shot.season}/${shot.playing_for}/${shot.shooter_id}.png`;  // Set the source of the image
        img.style.width = '75px';  // Adjust the size as needed
        img.style.height = 'auto';

        // Append the text and image to the tooltip
        tooltip.appendChild(text);
        tooltip.appendChild(img);

        // Append the tooltip to the shot element
        shotElement.appendChild(tooltip);

        // if (shot.goal === 1) {
        //     shotElement.style.backgroundColor = 'pink'
        // }
        container.appendChild(shotElement);

        // Handle hover to show tooltip and bring it above all other elements
        shotElement.addEventListener('mouseenter', () => {
            tooltip.style.visibility = 'visible';
            tooltip.style.zIndex = '1000'; // Ensure tooltip is on top
            shotElement.style.zIndex = '999'; // Temporarily bring the shot above others
        });

        shotElement.addEventListener('mouseleave', () => {
            tooltip.style.visibility = 'hidden';
            shotElement.style.zIndex = '10'; // Reset shot's z-index after hover
        });
    });

}

// Function to determine if a color is similar to black or gray
function isBlackGrayOrWhite(rgb) {
    const [r, g, b] = rgb;
    const blackThreshold = 50; // Threshold for black
    const whiteThreshold = 220; // Threshold for white
    const grayThreshold = 60; // Maximum difference to consider it gray

    const isBlack = r < blackThreshold && g < blackThreshold && b < blackThreshold;
    const isWhite = r > whiteThreshold && g > whiteThreshold && b > whiteThreshold;
    const isGray = !isBlack && !isWhite && Math.abs(r - g) < grayThreshold && Math.abs(r - b) < grayThreshold && Math.abs(g - b) < grayThreshold;

    return isBlack || isGray || isWhite;
}

// Function to get the most colorful color that is not similar to black or gray
function getMostColorfulColor(palette) {
    if (palette.length === 0) {
        throw new Error('Palette is empty');
    }

    let mostColorfulColor = palette[0];
    let highestSaturation = 0;

    palette.forEach(rgb => {
        if (!isBlackGrayOrWhite(rgb)) {
            const [h, s, l] = rgbToHsl(...rgb);
            if (s > highestSaturation) {
                highestSaturation = s;
                mostColorfulColor = rgb;
            }
        }
    });

    // If all colors are similar to black, gray, or white, return the color with the highest saturation
    if (highestSaturation === 0) {
        palette.forEach(rgb => {
            const [h, s, l] = rgbToHsl(...rgb);
            if (s > highestSaturation) {
                highestSaturation = s;
                mostColorfulColor = rgb;
            }
        });
    }

    return mostColorfulColor;
}

// Convert RGB to HSL
function rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
            default:
                break;
        }
        h /= 6;
    }

    return [h, s, l];
}

// Convert HSL to RGB
function hslToRgb(h, s, l) {
    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p, q, t) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 3) return q;
            if (t < 1 / 2) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

// Adjust color by reducing saturation
function adjustColor(r, g, b, factor) {
    const [h, s, l] = rgbToHsl(r, g, b);
    const newS = s * factor;
    return hslToRgb(h, newS, l);
}

function brightenColor(r, g, b, factor) {
    const [h, s, l] = rgbToHsl(r, g, b);
    const newL = Math.min(l * (1 + factor), 1); // Increase lightness, but cap at 1
    return hslToRgb(h, s, newL);
}

// Adjust color by decreasing lightness
function darkenColor(r, g, b, factor) {
    const [h, s, l] = rgbToHsl(r, g, b);
    const newL = Math.max(l * (1 - factor), 0); // Decrease lightness
    return hslToRgb(h, s, newL);
}

// Get the darkest color from the top 2 colors, excluding black
function getDarkestColor(palette) {
    if (palette.length === 0) {
        throw new Error('Palette is empty');
    }
    if (palette.length < 2) {
        return palette[0];
    }

    const colors = palette.slice(0, 2).map(rgb => ({
        rgb,
        hsl: rgbToHsl(...rgb)
    }));

    const nonBlackColors = colors.filter(color => !(color.rgb[0] === 0 && color.rgb[1] === 0 && color.rgb[2] === 0));
    if (nonBlackColors.length === 0) {
        throw new Error('All colors are black');
    }

    const darkestColor = nonBlackColors.reduce((darkest, current) => {
        return current.hsl[2] < darkest.hsl[2] ? current : darkest;
    });

    return darkestColor.rgb;
}

// Get the lightest color from the top 2 colors
function getLightestColor(palette) {
    if (palette.length === 0) {
        throw new Error('Palette is empty');
    }
    if (palette.length < 2) {
        return palette[0];
    }

    const [color1, color2] = palette.slice(0, 2).map(rgb => rgbToHsl(...rgb));
    const lightestColor = color1[2] > color2[2] ? palette[0] : palette[1];
    return lightestColor;
}

// Get the most medium color from the top 4 colors
function getMostMediumColor(palette) {
    if (palette.length === 0) {
        throw new Error('Palette is empty');
    }
    if (palette.length < 4) {
        return palette[0];
    }

    const hslColors = palette.slice(0, 4).map(rgb => ({
        rgb,
        hsl: rgbToHsl(...rgb)
    }));

    const targetLightness = 0.5; // Medium lightness in HSL
    let closestColor = hslColors[0];
    let closestDifference = Math.abs(hslColors[0].hsl[2] - targetLightness);

    hslColors.forEach(color => {
        const difference = Math.abs(color.hsl[2] - targetLightness);
        if (difference < closestDifference) {
            closestDifference = difference;
            closestColor = color;
        }
    });

    return closestColor.rgb;
}

// Calculate brightness of an RGB color
function calculateBrightness(r, g, b) {
    return (r * 299 + g * 587 + b * 114) / 1000;
}

async function getPredominantColor(imageUrl) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = imageUrl;

        img.onload = () => {
            try {
                const colorThief = new ColorThief();
                const palette = colorThief.getPalette(img, 5); // Get the top 5 colors in the image
                if (!Array.isArray(palette) || palette.length === 0) {
                    throw new Error('Palette is not an array or is empty');
                }
                const dominantColor = getDarkestColor(palette);
                if (!Array.isArray(dominantColor) || dominantColor.length !== 3) {
                    throw new Error('dominantColor is not an array of length 3');
                }
                const lessVibrantColor = brightenColor(...dominantColor, 0.55);
                resolve(lessVibrantColor);
            } catch (error) {
                reject(error);
            }
        };

        img.onerror = (error) => {
            reject(error);
        };
    });
}


function calculateXgPercentages(num1, num2) {
    num1 = parseFloat(num1);
    num2 = parseFloat(num2);
    if (num1 === 0 && num2 === 0) {
        return [50, 50];
    }
    const total = num1 + num2;
    const percentage1 = (num1 / total) * 100;
    const percentage2 = (num2 / total) * 100;
    return [percentage1, percentage2];
}


const getColorForEvShare = (value) => {
    if (value >= 83.33) return '#7cc47b'; // Darker green
    if (value >= 66.67 && value < 83.33) return '#a4e98b'; // Green
    if (value >= 50.00 && value < 66.67) return '#d9fcd2'; // Light green
    if (value >= 33.33 && value < 50.00) return '#fcd29f'; // Light orange
    if (value >= 16.67 && value < 33.33) return '#f7a259'; // Orange
    if (value < 16.67) return '#f76c5e'; // Red
    return '#fff'; // Default white
};

const getColorForExEvGf = (value) => {
    if (value >= 0 && value < 0.3333) return '#f76c5e';
    if (value >= 0.3333 && value < 0.6667) return '#f7a259';
    if (value >= 0.6667 && value < 1.0) return '#fcd29f';
    if (value >= 1.0 && value < 1.3333) return '#d9fcd2';
    if (value >= 1.3333 && value < 1.6667) return '#a4e98b';
    if (value >= 1.6667) return '#7cc47b';
    return '#fff'; // Default white
};

const getColorForMatchupExEvGf = (value) => {
    if (value >= 0.0 && value < 0.15) return '#f7a259';
    if (value >= 0.15 && value < 0.3) return '#fcd29f';
    if (value >= 0.3 && value < 0.45) return '#d9fcd2';
    if (value >= 0.45 && value < 0.6) return '#a4e98b';
    if (value >= 0.6) return '#7cc47b';
    return '#fff'; // Default white
};

const getColorForExEvGa = (value) => {
    if (value >= 0 && value < 0.3333) return '#7cc47b'; // Light green
    if (value >= 0.3333 && value < 0.6667) return '#a4e98b'; // Green
    if (value >= 0.6667 && value < 1.0) return '#d9fcd2'; // Darker green
    if (value >= 1.0 && value < 1.3333) return '#fcd29f'; // Light orange
    if (value >= 1.3333 && value < 1.6667) return '#f7a259'; // Orange
    if (value >= 1.6667) return '#f76c5e'; // Red
    return '#fff'; // Default white
};

const getColorForMatchupExEvGa = (value) => {
    if (value >= 0.0 && value < 0.15) return '#7cc47b';
    if (value >= 0.15 && value < 0.3) return '#a4e98b';
    if (value >= 0.3 && value < 0.45) return '#d9fcd2';
    if (value >= 0.45 && value < 0.6) return '#fcd29f';
    if (value >= 0.6 && value < 0.75) return '#f7a259';
    if (value >= 0.75) return '#f76c5e';
    return '#fff'; // Default white
};

const getColorForExGoalsScored = (value) => {
    if (value === 0) return '#f76c5e'; // Red
    if (value >= 0 && value < 0.1) return '#f7a259'; // Orange
    if (value >= 0.1 && value < 0.2) return '#fcd29f'; // Light orange
    if (value >= 0.2 && value < 0.3) return '#d9fcd2'; // Light green
    if (value >= 0.3 && value < 0.4) return '#a4e98b'; // Green
    if (value >= 0.4) return '#7cc47b'; // Darker green
    return '#fff'; // Default white
};

const getColorForMatchupExGoalsScored = (value) => {
    if (value >= 0 && value < 0.1) return '#fcd29f'; // Red
    if (value >= 0.1 && value < 0.3) return '#d9fcd2'; // Light green
    if (value >= 0.3 && value < 0.5) return '#a4e98b'; // Green
    if (value >= 0.5) return '#7cc47b'; // Darker green
    return '#fff'; // Default white
};

const getColorForShotsOrBlocks = (value) => {
    // if (value === 0) return '#f76c5e'; // Red
    // if (value === 1) return '#f7a259'; // Orange
    if (value === 0) return '#d9fcd2'; // Light orange
    if (value > 0 && value <= 4) return '#a4e98b'; // Light green
    if (value > 4) return '#7cc47b'; // Darker green
    return '#fff'; // Default white
};

const getColorForToi = (value) => {
    if (value < 680) return '#f76c5e';
    if (value >= 680 && value < 870) return '#f7a259';
    if (value >= 870 && value < 1060) return '#fcd29f';
    if (value >= 1060 && value < 1250) return '#d9fcd2';
    if (value >= 1250 && value < 1440) return '#a4e98b';
    if (value >= 1440) return '#7cc47b';
    return '#fff'; // Default white
};

const getColorForEvToi = (value) => {
    if (value < 600) return '#f76c5e'; // Red
    if (value >= 600 && value < 700) return '#fcd29f'; // Light orange
    if (value >= 700 && value < 800) return '#d9fcd2'; // Light green
    if (value >= 800 && value < 900) return '#a4e98b'; // Green
    if (value >= 900 && value < 1000) return '#7cc47b'; // Darker green
    if (value >= 1000) return '#4caf50'; // Dark green
    return '#fff'; // Default white
};

const getColorForGoalsOrAssists = (value) => {
    if (value === 0) return '#d9fcd2';
    if (value > 0 && value <= 1) return '#a4e98b';
    if (value > 1 && value <= 2) return '#7cc47b';
    if (value > 2) return '#4caf50';
    return '#fff'; // Default white
};

const getColorForMargins = (value) => {
    if (value < -3) return '#f76c5e'; // Red
    if (value >= -3 && value < 0) return '#fcd29f'; // Light orange
    if (value === 0) return '#d9fcd2'; // Light green
    if (value === 0) return '#a4e98b'; // Green
    if (value >= 1 && value <= 3) return '#7cc47b'; // Darker green
    if (value > 3) return '#4caf50'; // Dark green
    return '#fff'; // Default white
};

const getColorForStarts = (value) => {
    if (value === 0) return '#d9fcd2'; // Light green
    if (value > 0 && value <= 2) return '#a4e98b'; // Green
    if (value > 2 && value <= 4) return '#7cc47b'; // Darker green
    if (value > 4) return '#4caf50'; // Dark green
    return '#fff'; // Default white
};

export const getColorForGameScore = (value) => {
    if (value >= 8.33) return '#7cc47b'; // Darker green
    if (value >= 6.66 && value < 8.33) return '#a4e98b'; // Green
    if (value >= 5.00 && value < 6.66) return '#d9fcd2'; // Light green
    if (value >= 3.33 && value < 5.00) return '#fcd29f'; // Light orange
    if (value >= 1.66 && value < 3.33) return '#f7a259'; // Orange
    if (value < 1.66) return '#f76c5e'; // Red
    return '#fff'; // Default white
};

const getColorForShotsFaced = (value) => {
    if (value >= 40) return '#4caf50'; // Darker green
    if (value >= 35 && value < 40) return '#7cc47b'; // Green
    if (value >= 30 && value < 35) return '#a4e98b'; // Light greene
    if (value < 30) return '#d9fcd2'; // Red
    return '#fff'; // Default white
};

const getColorForGoalsAgainst = (value) => {
    if (value === 0) return '#4caf50';
    if (value  === 1) return '#7cc47b';
    if (value === 2) return '#a4e98b';
    if (value === 3) return '#d9fcd2';
    if (value === 4) return '#f7a259';
    if (value >= 4) return '#f76c5e';
    return '#fff'; // Default white
};

const getColorForSvPercent = (value) => {
    if (value >= 99) return '#4caf50'; // Darker green
    if (value >= 95 && value < 99) return '#a4e98b'; // Green
    if (value >= 91 && value < 95) return '#d9fcd2'; // Light green
    if (value >= 87 && value < 91) return '#fcd29f'; // Light orange
    if (value >= 84 && value < 87) return '#f7a259'; // Orange
    if (value < 84) return '#f76c5e'; // Red
    return '#fff'; // Default white
};

const getColorForGSAx = (value) => {
    if (value < -1.0) return '#f76c5e';
    if (value >= -1.0 && value < -0.5) return '#f7a259';
    if (value >= -0.5 && value < 0.0) return '#d9fcd2';
    if (value >= 0.0 && value < 0.5) return '#a4e98b';
    if (value >= 0.5 && value < 1.0) return '#7cc47b';
    if (value >= 1.0) return '#4caf50';
    return '#fff'; // Default white
};

const getColorForxGA = (value) => {
    if (value < 3.0) return '#d9fcd2';
    if (value >= 3.0 && value < 3.5) return '#a4e98b';
    if (value >= 3.5 && value < 4.0) return '#7cc47b';
    if (value >= 4.0) return '#4caf50';
    return '#fff'; // Default white
};

function renderExpectedGoalsTable(expectedGoalsData) {
    if (!expectedGoalsData) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );// Or return a placeholder element, such as a loading spinner or message
    }
    return (
        <div className={styles.tablesContainer}>
            <div className={styles.tableWrapper}>
                <h2 className={styles.tableTitle}>Expected Goals</h2>
                <table className={styles.xgTable}>
                    <thead>
                    <tr>
                        <th className={styles.header}>#</th>
                        <th className={styles.header}>Team</th>
                        <th className={styles.header}>Photo</th>
                        <th className={styles.header}>Name</th>
                        <th className={styles.header}>xG</th>
                        <th className={styles.header}>SOG</th>
                        {/*<th className={styles.header}>xGA</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(expectedGoalsData)
                        .sort(([, a], [, b]) => b.expected_goals - a.expected_goals)
                        .slice(0, 10) // Limit to the first 10 items
                        .map(([playerId, playerDict], index) => (
                            <tr key={playerId} className={styles.playerRow}>
                                <td className={styles.playerRank}>{index + 1}.</td>
                                <td>
                                    <img className={styles.logo}
                                         src={`https://assets.nhle.com/logos/nhl/svg/${playerDict.team_abb}_light.svg`}
                                         alt={`${playerDict.team_abb}`}/>
                                </td>
                                <td className={styles.playerMug}>
                                    <img className={styles.mug}
                                         src={`https://assets.nhle.com/mugs/nhl/${playerDict.season}/${playerDict.team_abb}/${playerId}.png`}
                                         alt={`${playerDict.name}`}/>
                                </td>
                                <td className={styles.playerName}>
                                    <Link to={`/players/id/${playerId}`} className={styles.playerLink}>
                                        {playerDict.name}
                                    </Link>
                                </td>
                                <td className={styles.expectedGoals}>
                                    {playerDict.expected_goals}
                                </td>
                                <td className={styles.expectedGoals}>
                                    {playerDict.shots_on_goal}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/*<div className={styles.tableWrapper}>*/}
            {/*    <h2 className={styles.tableTitle}>Shots on Goal</h2>*/}
            {/*    <table className={styles.xgTable}>*/}
            {/*        <tbody>*/}
            {/*        {Object.entries(expectedGoalsData)*/}
            {/*            .sort(([, a], [, b]) => b.shots_on_goal - a.shots_on_goal)*/}
            {/*            .slice(0, 10) // Limit to the first 10 items*/}
            {/*            .map(([playerId, playerDict], index) => (*/}
            {/*                <tr key={playerId} className={styles.playerRow}>*/}
            {/*                    <td className={styles.playerRank}>{index + 1}.</td>*/}
            {/*                    <td>*/}
            {/*                        <img className={styles.logo}*/}
            {/*                             src={`https://assets.nhle.com/logos/nhl/svg/${playerDict.team_abb}_light.svg`}*/}
            {/*                             alt={`${playerDict.team_abb}`}/>*/}
            {/*                    </td>*/}
            {/*                    <td className={styles.playerMug}>*/}
            {/*                        <img className={styles.mug}*/}
            {/*                             src={`https://assets.nhle.com/mugs/nhl/20232024/${playerDict.team_abb}/${playerId}.png`}*/}
            {/*                             alt={`${playerDict.name}`}/>*/}
            {/*                    </td>*/}
            {/*                    <td className={styles.playerName}>*/}
            {/*                        <Link to={`/players/id/${playerId}`} className={styles.playerLink}>*/}
            {/*                            {playerDict.name}*/}
            {/*                        </Link>*/}
            {/*                    </td>*/}
            {/*                    <td className={styles.expectedGoals}>*/}
            {/*                        {playerDict.shots_on_goal}*/}
            {/*                    </td>*/}
            {/*                </tr>*/}
            {/*            ))}*/}
            {/*        </tbody>*/}
            {/*    </table>*/}
            {/*</div>*/}
        </div>
    );
}


function colorExpectedGoalMap(teamLeftTotalXgColor, teamLeftBorder, teamRightTotalXgColor, teamRightBorder) {
    const winnerShots = Array.from(document.getElementsByClassName(`${styles.winnerShot}`));
    const winnerGoals = Array.from(document.getElementsByClassName(`${styles.winnerGoal}`));
    const loserShots = Array.from(document.getElementsByClassName(`${styles.loserShot}`));
    const loserGoals = Array.from(document.getElementsByClassName(`${styles.loserGoal}`));

    winnerShots.forEach(shot => shot.style.backgroundColor = teamLeftTotalXgColor);
    winnerShots.forEach(shot => shot.style.border = `1px solid ${teamLeftBorder}`);
    winnerGoals.forEach(shot => shot.style.backgroundColor = teamLeftTotalXgColor);
    loserShots.forEach(shot => shot.style.backgroundColor = teamRightTotalXgColor);
    loserShots.forEach(shot => shot.style.border = `1px solid ${teamRightBorder}`);
    loserGoals.forEach(shot => shot.style.backgroundColor = teamRightTotalXgColor);
}

function renderShotMap(shotLocations) {
    if (!shotLocations) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    return (
        <div id="rinkContainer" className={styles.rinkContainer}>
            <img id="rink" className={styles.rink} src={`${process.env.PUBLIC_URL}/rink.jpg`}
                 alt={'nhl rink'}/>
        </div>
    )
}


function renderMuffinOfTheGame(skaterPerformanceDict, goaliePerformanceDict) {
  // Merge the skater and goalie performance dictionaries
  const allPlayers = { ...skaterPerformanceDict, ...goaliePerformanceDict };

  if (Object.keys(allPlayers).length === 0) {
    return null; // or display a message indicating no data is available
  }

  // Find the player with the highest game_score
  const topPlayerId = Object.keys(allPlayers).reduce((prevPlayerId, currentPlayerId) => {
    const prevGameScore = allPlayers[prevPlayerId].game_score;
    const currentGameScore = allPlayers[currentPlayerId].game_score;
    return currentGameScore > prevGameScore ? currentPlayerId : prevPlayerId;
  });

  const topPlayer = allPlayers[topPlayerId];

  // Return the JSX to display the Player of the Game
  return (
  <div className={styles.muffinOfTheGameContainer}>
    <div className={styles.headingContainer}>
      {/* First image */}
      {/*<img*/}
      {/*  className={styles.stickImage}*/}
      {/*  src={`${process.env.PUBLIC_URL}/playerofthegamestick.png`}*/}
      {/*  alt="Player of the Game Stick"*/}
      {/*/>*/}

      {/* Heading */}
      <h2
        className={styles.muffinOfTheGameHeading}
      >
        Player of the Game
      </h2>

      {/* Second image */}
      {/*<img*/}
      {/*  className={styles.stickImage}*/}
      {/*  src={`${process.env.PUBLIC_URL}/playerofthegamestick.png`}*/}
      {/*  alt="Player of the Game Stick"*/}
      {/*/>*/}
    </div> {/* Close headingContainer here */}

    {/* Content below the heading */}
      <div className={styles.muffinOfTheGameContent}>
          {/*<img*/}
          {/*    className={styles.muffinGif}*/}
          {/*    src={`${process.env.PUBLIC_URL}/muffinman.png`}*/}
          {/*    alt="Muffin of the Game"*/}
          {/*/>*/}
          <div className={styles.muffinOfTheGameInfo}>
              <Link to={`/players/id/${topPlayerId}`}>
                  <img
                      className={styles.mug}
                      src={`https://assets.nhle.com/mugs/nhl/${topPlayer.season}/${topPlayer.team_abb}/${topPlayerId}.png`}
                      alt={topPlayer.name}
                  />
              </Link>
              <div className={styles.muffinPlayerDetails}>
                  <Link to={`/players/id/${topPlayerId}`} className={styles.muffinOfTheGameName}
                  style={{'--primary-color': topPlayer.primary_color}}
                  >
                    {topPlayer.name}
                      {/*{topPlayer.name} — {topPlayer.game_score.toFixed(2)}*/}
                  </Link>
                  <div
                      className={styles.gameScoreBox}
                      style={{backgroundColor: getColorForGameScore(topPlayer.game_score)}}
                  >
                      {topPlayer.game_score.toFixed(2)}
                  </div>
              </div>
          </div>
          {/*<img*/}
          {/*    className={styles.muffinGif}*/}
          {/*    src={`${process.env.PUBLIC_URL}/muffinman.png`}*/}
          {/*    alt="Muffin of the Game"*/}
          {/*/>*/}
      </div>
  </div>
  );
}


function checkForStolenGame(goaliePerformanceDict, game) {
  // Check if goaliePerformanceDict is valid
  if (!goaliePerformanceDict || Object.keys(goaliePerformanceDict).length === 0) {
    return null;
  }

  const goalDifferential = game.winner_score - game.loser_score;

  // Iterate over each goalie in the performance dictionary
  for (const playerId in goaliePerformanceDict) {
    const playerDict = goaliePerformanceDict[playerId];

    // Check if the goalie played for the winning team
    if (playerDict.team_abb === game.winner) {
      // Check if gsax is greater than or equal to the goal differential
      if (playerDict.gsax >= goalDifferential) {
        // Return the playerDict or handle as needed
        return (
            <div className={styles.stolenGameContainer}>
                <div className={styles.stolenImageContainer}>
                    <img
                        className={styles.stolenImage}
                        src={`${process.env.PUBLIC_URL}/stolen.png`}
                        alt="Stolen Game"
                    />
                    <span className={styles.tooltipText}>
                      A game is considered stolen when a goaltender's Goals Saved Above Expected (GSAx) are greater than the margin of his team's victory.
                    </span>
                </div>
                <p className={styles.stolenGameText}>
                    {/*<Link to={`/players/id/${playerId}`}>*/}
                    {/*    <img*/}
                    {/*        className={styles.mug}*/}
                    {/*        src={`https://assets.nhle.com/mugs/nhl/${playerDict.season}/${playerDict.team_abb}/${playerId}.png`}*/}
                    {/*        alt={playerDict.name}*/}
                    {/*    />*/}
                    {/*</Link>*/}
                    <Link to={`/players/id/${playerId}`} className={styles.playerLink}>
                        {playerDict.name}
                    </Link>{' '}
                    stole this game, saving{' '}
                    {playerDict.gsax.toFixed(2)} more goals than expected.
                </p>
                <div className={styles.deadOrAliveContainer}>
                    <img
                        className={styles.deadOrAliveImg}
                        src={`${process.env.PUBLIC_URL}/deadoralive.png`}
                        alt="Wanted Dead or Alive"
                    />
                    <img
                        className={styles.deadOrAliveMug}
                        src={`https://assets.nhle.com/mugs/nhl/${playerDict.season}/${playerDict.team_abb}/${playerId}.png`}
                        alt={playerDict.name}
                    />
                </div>
            </div>
        );
      }
    }
  }

    // If no goalie meets the criteria, return null or handle accordingly
    return null;
}

export function renderSkaterTable(gamePerformanceData) {
    if (!gamePerformanceData) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );// Or return a placeholder element, such as a loading spinner or message
    }
    if (!gamePerformanceData || Object.keys(gamePerformanceData).length === 0) {
        return (
            <div className="d-flex justify-content-center">
                <p>This game's skater data is not yet available.</p>
            </div>
        );
    }
    return (
        <div className={styles.tablesContainer}>
            <div className={styles.tableWrapper}>
                <h2 className={styles.tableTitle}>Player Ratings</h2>
                <table className={styles.xgTable}>
                    <thead>
                    <tr>
                        <th className={styles.header}>#</th>
                        <th className={styles.header}>Team</th>
                        <th className={styles.header}>Photo</th>
                        <th className={styles.header} style={{width: "210px"}}>Name</th>
                        <th className={styles.header}>TOI</th>
                        <th className={styles.header}>EV TOI</th>
                        <th className={styles.header}>EV xGF%</th>
                        <th className={styles.header}>EV xGF</th>
                        <th className={styles.header}>EV xGA</th>
                        <th className={styles.header}>xG</th>
                        <th className={styles.header}>G</th>
                        <th className={styles.header}>A</th>
                        {/*<th className={styles.header}>A2</th>*/}
                        <th className={styles.header}>SOG</th>
                        <th className={styles.header}>Blk</th>
                        <th className={styles.header}>TO Diff</th>
                        <th className={styles.header}>FO Diff</th>
                        <th className={styles.header}>EV DZS</th>
                        <th className={styles.header}>EV OZS</th>
                        <th className={styles.header}>Game Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(gamePerformanceData)
                        .sort(([, a], [, b]) => b.game_score - a.game_score)
                        // .slice(0, 10) // Limit to the first 10 items
                        .map(([playerId, playerDict], index) => (
                            <tr key={playerId} className={styles.playerRow}>
                                <td className={styles.playerRank}>{index + 1}.</td>
                                <td>
                                    <img className={styles.logo}
                                         src={`https://assets.nhle.com/logos/nhl/svg/${playerDict.team_abb}_light.svg`}
                                         alt={`${playerDict.team_abb}`}/>
                                </td>
                                <td className={styles.playerMug}>
                                    <Link to={`/players/id/${playerId}`} className={styles.playerLink}>
                                        <img className={styles.mug}
                                             src={`https://assets.nhle.com/mugs/nhl/${playerDict.season}/${playerDict.team_abb}/${playerId}.png`}
                                             alt={`${playerDict.name}`}/>
                                    </Link>
                                </td>
                                <td className={styles.playerName}>
                                    <Link to={`/players/id/${playerId}`} className={styles.playerLink}>
                                        {playerDict.name}
                                    </Link>
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForToi(playerDict.total_toi_int)}}>
                                    {playerDict.toi_total_disp}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForEvToi(playerDict.ev_toi_int)}}>
                                    {playerDict.toi_ev_disp}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForEvShare(playerDict.share)}}>
                                    {playerDict.share + '%'}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForExEvGf(playerDict.expected_for_values)}}>
                                    {playerDict.expected_for_values}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForExEvGa(playerDict.expected_against_values)}}>
                                    {playerDict.expected_against_values}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForExGoalsScored(playerDict.expected_goals)}}>
                                    {playerDict.expected_goals}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForGoalsOrAssists(playerDict.goals)}}>
                                    {playerDict.goals}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForGoalsOrAssists(playerDict.primaries + playerDict.secondaries)}}>
                                    {playerDict.primaries + playerDict.secondaries}
                                </td>
                                {/*<td className={styles.expectedGoals}*/}
                                {/*    style={{backgroundColor: getColorForGoalsOrAssists(playerDict.secondaries)}}>*/}
                                {/*    {playerDict.secondaries}*/}
                                {/*</td>*/}
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForShotsOrBlocks(playerDict.shots_on_goal)}}>
                                    {playerDict.shots_on_goal}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForShotsOrBlocks(playerDict.blocks)}}>
                                    {playerDict.blocks}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForMargins(playerDict.to_margin)}}>
                                    {playerDict.to_margin}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForMargins(playerDict.fo_margin)}}>
                                    {playerDict.fo_margin}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForStarts(playerDict.ev_dz_starts)}}>
                                    {playerDict.ev_dz_starts}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForStarts(playerDict.ev_oz_starts)}}>
                                    {playerDict.ev_oz_starts}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{fontWeight: "bold", backgroundColor: getColorForGameScore(playerDict.game_score)}}>
                                    {playerDict.game_score}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function renderGoalieTable(gamePerformanceData) {
    if (!gamePerformanceData) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );// Or return a placeholder element, such as a loading spinner or message
    }
    if (!gamePerformanceData || Object.keys(gamePerformanceData).length === 0) {
        return (
            <div className="d-flex justify-content-center">
                <p>This game's goalie data is not yet available.</p>
            </div>
        );
    }
    return (
        <div className={styles.tablesContainer}>
            <div className={styles.tableWrapper}>
                <h2 className={styles.tableTitle}>Goalie Ratings</h2>
                <table className={styles.xgTable}>
                    <thead>
                    <tr>
                        <th className={styles.header}>#</th>
                        <th className={styles.header}>Team</th>
                        <th className={styles.header}>Photo</th>
                        <th className={styles.header} style={{width: "210px"}}>Name</th>
                        <th className={styles.header}>TOI</th>
                        <th className={styles.header}>Shots Faced</th>
                        <th className={styles.header}>Goals Allowed</th>
                        <th className={styles.header}>Saves</th>
                        <th className={styles.header}>Sv%</th>
                        <th className={styles.header}>xGA</th>
                        <th className={styles.header}>GSAx</th>
                        <th className={styles.header}>Game Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(gamePerformanceData)
                        .sort(([, a], [, b]) => b.game_score - a.game_score)
                        // .slice(0, 10) // Limit to the first 10 items
                        .map(([playerId, playerDict], index) => (
                            <tr key={playerId} className={styles.playerRow}>
                                <td className={styles.playerRank}>{index + 1}.</td>
                                <td>
                                    <img className={styles.logo}
                                         src={`https://assets.nhle.com/logos/nhl/svg/${playerDict.team_abb}_light.svg`}
                                         alt={`${playerDict.team_abb}`}/>
                                </td>
                                <td className={styles.playerMug}>
                                    <Link to={`/players/id/${playerId}`} className={styles.playerLink}>
                                        <img className={styles.mug}
                                             src={`https://assets.nhle.com/mugs/nhl/${playerDict.season}/${playerDict.team_abb}/${playerId}.png`}
                                             alt={`${playerDict.name}`}/>
                                    </Link>
                                </td>
                                <td className={styles.playerName}>
                                    <Link to={`/players/id/${playerId}`} className={styles.playerLink}>
                                        {playerDict.name}
                                    </Link>
                                </td>
                                <td className={styles.expectedGoals}>
                                    {playerDict.toi_total_disp}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForShotsFaced(playerDict.shots_faced)}}>
                                    {playerDict.shots_faced}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForGoalsAgainst(playerDict.goals_against)}}>
                                    {playerDict.goals_against}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForShotsFaced(playerDict.saves)}}>
                                    {playerDict.saves}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForSvPercent(playerDict.sv_percentage)}}>
                                    {playerDict.sv_percentage + '%'}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForxGA(playerDict.xgs_against)}}>
                                    {playerDict.xgs_against}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForGSAx(playerDict.gsax)}}>
                                    {playerDict.gsax}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{
                                        fontWeight: "bold",
                                        backgroundColor: getColorForGameScore(playerDict.game_score)
                                    }}>
                                    {playerDict.game_score.toFixed(2)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}


function renderMatchupTable(matchupData, selectedPlayerId, selectedPlayerGamePerformance) {
    if (!matchupData && !selectedPlayerId) {
        return null
        //     <div className="d-flex justify-content-center">
        //         <p>There is no matchup data for this game yet. Check back soon!</p>
        //         {/*<div className="spinner-border" role="status">*/}
        //         {/*    <span className="visually-hidden">Loading...</span>*/}
        //         {/*</div>*/}
        //     </div>
        // );// Or return a placeholder element, such as a loading spinner or message
    }
    if (!matchupData && selectedPlayerId) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.tablesContainer}>
            <div className={styles.tableWrapper}>
                <h2 className={styles.tableTitle}>
                    <img className={styles.matchupMug}
                         src={`https://assets.nhle.com/mugs/nhl/${selectedPlayerGamePerformance.season}/${selectedPlayerGamePerformance.team_abb}/${selectedPlayerId}.png`}
                         alt={`${selectedPlayerGamePerformance.name}`}/>
                    {`${selectedPlayerGamePerformance.name}: Matchup Results`}</h2>
                <h2 className={styles.tableSubtitle}>
                    {/*<img className={styles.matchupMug}*/}
                    {/*     src={`https://assets.nhle.com/mugs/nhl/20232024/${selectedPlayerGamePerformance.team_abb}/${selectedPlayerId}.png`}*/}
                    {/*     alt={`${selectedPlayerGamePerformance.name}`}/>*/}
                    {`Numbers reflect ${selectedPlayerGamePerformance.name} performance against respective player, ordered by shared TOI.`}</h2>
                <table className={styles.xgTable}>
                    <thead>
                    <tr>
                        <th className={styles.header}>#</th>
                        <th className={styles.header}>Team</th>
                        <th className={styles.header}>Photo</th>
                        <th className={styles.header}>Position</th>
                        <th className={styles.header}>Against</th>
                        <th className={styles.header}>Shared EV TOI</th>
                        <th className={styles.header}>EV xG</th>
                        <th className={styles.header}>EV xGF%</th>
                        <th className={styles.header}>EV xGF</th>
                        <th className={styles.header}>EV xGA</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(matchupData)
                        .sort(([, a], [, b]) => Number(b.ev_toi_int) - Number(a.ev_toi_int))  // Convert ev_toi_int to a number for correct sorting
                        .map(([playerId, headToHeadData], index) => (
                            <tr key={playerId} className={styles.playerRow}>
                                <td className={styles.playerRank}>{index + 1}.</td>
                                <td>
                                    <img className={styles.logo}
                                         src={`https://assets.nhle.com/logos/nhl/svg/${headToHeadData.team_abb}_light.svg`}
                                         alt={`${headToHeadData.team_abb}`}/>
                                </td>
                                <td className={styles.playerMug}>
                                    <Link to={`/players/id/${playerId}`} className={styles.playerLink}>
                                        <img className={styles.mug}
                                             src={`https://assets.nhle.com/mugs/nhl/${headToHeadData.season}/${headToHeadData.team_abb}/${playerId}.png`}
                                             alt={`${headToHeadData.name}`}/>
                                    </Link>
                                </td>
                                <td className={styles.playerName}>
                                    <Link to={`/players/id/${playerId}`} className={styles.playerLink}>
                                        {headToHeadData.position}
                                    </Link>
                                </td>
                                <td className={styles.playerName}>
                                    {'vs. ' + headToHeadData.name}
                                </td>
                                <td className={styles.expectedGoals}>
                                    {headToHeadData.ev_toi_str}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForMatchupExGoalsScored(headToHeadData.xg)}}>
                                    {headToHeadData.xg}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForEvShare(headToHeadData.ev_xgf_percent)}}>
                                    {headToHeadData.ev_xgf_percent + '%'}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForMatchupExEvGf(headToHeadData.ev_xg_for)}}>
                                    {headToHeadData.ev_xg_for}
                                </td>
                                <td className={styles.expectedGoals}
                                    style={{backgroundColor: getColorForMatchupExEvGa(headToHeadData.ev_xg_ag)}}>
                                    {headToHeadData.ev_xg_ag}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function hexToRgb(hex) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return [r, g, b];
}


function GameRecap({game, expectedGoalsData, playerData, shotData}) {

    useEffect(() => {
        async function setTeamColors() {
            // const teamLeftTotalXgColor = await getPredominantColor(`${process.env.PUBLIC_URL}/team_logos/${game.winner}.png`);
            // const teamRightTotalXgColor = await getPredominantColor(`${process.env.PUBLIC_URL}/team_logos/${game.loser}.png`);
            const teamLeftTotalXgColor = game.winner_primary_color
            const teamRightTotalXgColor = game.loser_primary_color

            const elementsToColor = [
                {element: document.getElementById('teamLeftTotalXgPercentage'), color: teamLeftTotalXgColor},
                {element: document.getElementById('teamRightTotalXgPercentage'), color: teamRightTotalXgColor},
                {element: document.getElementById('teamLeftEvXgPercentage'), color: teamLeftTotalXgColor},
                {element: document.getElementById('teamRightEvXgPercentage'), color: teamRightTotalXgColor},
                {element: document.getElementById('teamLeftStXgPercentage'), color: teamLeftTotalXgColor},
                {element: document.getElementById('teamRightStXgPercentage'), color: teamRightTotalXgColor},
            ];

            elementsToColor.forEach(({element, color}) => {
                if (element) {
                    element.style.backgroundColor = color;
                }
            });

            // const winnerShots = Array.from(document.getElementsByClassName(`${styles.winnerShot}`));
            // const winnerGoals = Array.from(document.getElementsByClassName(`${styles.winnerGoal}`));
            // const loserShots = Array.from(document.getElementsByClassName(`${styles.loserShot}`));
            // const loserGoals = Array.from(document.getElementsByClassName(`${styles.loserGoal}`));
            // winnerShots.forEach(shot => shot.style.backgroundColor = `rgb(${teamLeftTotalXgColor.join(',')})`);
            // winnerGoals.forEach(shot => shot.style.backgroundColor = `rgb(${teamLeftTotalXgColor.join(',')})`);
            // loserShots.forEach(shot => shot.style.backgroundColor = `rgb(${teamRightTotalXgColor.join(',')})`);
            // loserGoals.forEach(shot => shot.style.backgroundColor = `rgb(${teamRightTotalXgColor.join(',')})`);

            const teamLeftRgb = hexToRgb(teamLeftTotalXgColor);
            const teamRightRgb = hexToRgb(teamRightTotalXgColor);

            const teamLeftBrightness = calculateBrightness(...teamLeftRgb);
            const teamRightBrightness = calculateBrightness(...teamRightRgb);

            const adjustTextColor = (items, brightness) => {
                if (brightness > 200) {
                    items.forEach(text => text.style.color = '#595959');
                }
            };

            adjustTextColor(document.getElementsByName('teamLeftXgText'), teamLeftBrightness);
            adjustTextColor(document.getElementsByName('teamRightXgText'), teamRightBrightness);
        }

        setTeamColors();
    }, [game]);

    // Separate effect for plotting shots
    useEffect(() => {
        async function applyColors() {
            // const teamLeftTotalXgColor = await getPredominantColor(`${process.env.PUBLIC_URL}/team_logos/${game.winner}.png`);
            // const teamRightTotalXgColor = await getPredominantColor(`${process.env.PUBLIC_URL}/team_logos/${game.loser}.png`);
            // const teamLeftTotalXgColor = game.winner_primary_color
            // const teamRightTotalXgColor = game.loser_primary_color

            // Plot the shots first
            if (shotData && shotData.shot_locations && shotData.shot_locations.winner && shotData.shot_locations.loser) {
                plotShots(shotData.shot_locations.winner, true);
                plotShots(shotData.shot_locations.loser, false);
            }

            // Then apply the colors
            colorExpectedGoalMap(game.winner_primary_color, game.winner_secondary_color, game.loser_primary_color, game.loser_secondary_color);
        }

        applyColors();
    }, [shotData]);

    // Separate effect for setting loading bar percentages
    useEffect(() => {
        const updateLoadingBars = () => {
            const [teamLeftTotalXgPercentage, teamRightTotalXgPercentage] = calculateXgPercentages(expectedGoalsData.winner_xg, expectedGoalsData.loser_xg);
            document.getElementById('teamLeftTotalXgPercentage').style.width = `${teamLeftTotalXgPercentage}%`;
            document.getElementById('teamRightTotalXgPercentage').style.width = `${teamRightTotalXgPercentage}%`;

            const [teamLeftEvXgPercentage, teamRightEvXgPercentage] = calculateXgPercentages(expectedGoalsData.winner_ev_xg, expectedGoalsData.loser_ev_xg);
            document.getElementById('teamLeftEvXgPercentage').style.width = `${teamLeftEvXgPercentage}%`;
            document.getElementById('teamRightEvXgPercentage').style.width = `${teamRightEvXgPercentage}%`;

            const [teamLeftStXgPercentage, teamRightStXgPercentage] = calculateXgPercentages(expectedGoalsData.winner_st_xg, expectedGoalsData.loser_st_xg);
            document.getElementById('teamLeftStXgPercentage').style.width = `${teamLeftStXgPercentage}%`;
            document.getElementById('teamRightStXgPercentage').style.width = `${teamRightStXgPercentage}%`;
        };

        updateLoadingBars();
    }, [expectedGoalsData]);

    const [loadingMatchupData, setLoadingMatchupData] = useState(false); // New state for loading spinner
    const [selectedPlayerGamePerformance, setSelectedPlayerGamePerformance] = useState(null);
    const [selectedPlayerId, setSelectedPlayer] = useState(null);
    const [matchupData, setMatchupData] = useState(null);
    const handlePlayerChange = (event) => {
        const playerId = event.target.value;
        setSelectedPlayerGamePerformance(playerData.skater_performance_dict[playerId])
        setSelectedPlayer(playerId);

        if (playerId) {
            setLoadingMatchupData(true); // Show the loading spinner
            const apiUrl = `https://muffinhockey.com/api/game/${game.id}/player/${playerId}/matchups`;
            // Make API request with the selected player ID
            fetch(apiUrl)
                .then((response) => response.json())
                .then((matchupData) => {
                    // console.log('Player data:', matchupData.head_to_head);
                    setMatchupData(matchupData.head_to_head);  // Store the fetched data in state
                    setLoadingMatchupData(false); // Hide the loading spinner
                    // Process and display the data in a table or however you need
                })
                .catch((error) => {
                    console.error('Error fetching player data:', error);
                    setLoadingMatchupData(false); // Hide the loading spinner
                });
        }
    };

    return (
        <div className={styles.pageContainer}>
            <div className={styles.recapContainer}>
                <div className={styles.scoreContainer}>
                    <Link key={game.winner} to={`/teams/${game.winner}/`}>
                        <img className={styles.teamLogo}
                             src={`https://assets.nhle.com/logos/nhl/svg/${game.winner}_light.svg`}
                             alt={`${game.winner} logo`}
                        />
                    </Link>
                    {renderGameScore(game)}
                    {/*<div className={styles.score} id="game-score">{game.winner_score + ' — ' + game.loser_score}</div>*/}
                    <Link key={game.loser} to={`/teams/${game.loser}/`}>
                        <img className={styles.teamLogo}
                             src={`https://assets.nhle.com/logos/nhl/svg/${game.loser}_light.svg`}
                             alt={`${game.loser} logo`}
                        />
                    </Link>
                </div>
                <div className={styles.loadingBarTitle}>
                    Total Expected Goals
                </div>
                <div className={styles.loadingBarContainer}>
                    <div className={styles.loadingBar} id="loading-bar">
                        <div className={styles.teamLeftXgPercentage} id="teamLeftTotalXgPercentage">
                            <span className={styles.xgText} name="teamLeftXgText">{expectedGoalsData.winner_xg}</span>
                        </div>
                        <div className={styles.teamRightXgPercentage} id="teamRightTotalXgPercentage">
                            <span className={styles.xgText} name="teamRightXgText">{expectedGoalsData.loser_xg}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.loadingBarTitle}>
                    Even Strength Expected Goals
                </div>
                <div className={styles.loadingBarContainer}>
                    <div className={styles.loadingBar} id="loading-bar">
                        <div className={styles.teamLeftXgPercentage} id="teamLeftEvXgPercentage">
                            <span className={styles.xgText}
                                  name="teamLeftXgText">{expectedGoalsData.winner_ev_xg}</span>
                        </div>
                        <div className={styles.teamRightXgPercentage} id="teamRightEvXgPercentage">
                            <span className={styles.xgText}
                                  name="teamRightXgText">{expectedGoalsData.loser_ev_xg}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.loadingBarTitle}>
                    Special Teams Expected Goals
                </div>
                <div className={styles.loadingBarContainer}>
                    <div className={styles.loadingBar} id="loading-bar">
                        <div className={styles.teamLeftXgPercentage} id="teamLeftStXgPercentage">
                            <span className={styles.xgText}
                                  name="teamLeftXgText">{expectedGoalsData.winner_st_xg}</span>
                        </div>
                        <div className={styles.teamRightXgPercentage} id="teamRightStXgPercentage">
                            <span className={styles.xgText}
                                  name="teamRightXgText">{expectedGoalsData.loser_st_xg}</span>
                        </div>
                    </div>
                </div>
                {checkForStolenGame(playerData.goalie_performance_dict, game)}
            </div>
            <hr className={styles.divider}/>
            <h2 className={styles.tableTitle}>xG Chart</h2>
            {renderShotMap(shotData.shot_locations)}
            <hr className={styles.divider}/>
            {renderMuffinOfTheGame(playerData.skater_performance_dict, playerData.goalie_performance_dict)}
            <hr className={styles.divider}/>
            {renderGoalieTable(playerData.goalie_performance_dict)}
            {renderSkaterTable(playerData.skater_performance_dict)}
            <div className={styles.dropdownContainer}>
                <label htmlFor="player-select" className={styles.dropdownLabel}>Select a Player for Matchup
                    Data:</label>
                <select id="player-select" className={styles.dropdown} onChange={handlePlayerChange}>
                    <option value="">Select a Player</option>
                    {/*{console.log(playerData)}*/}
                    {playerData.skater_performance_dict &&
                        Object.keys(playerData.skater_performance_dict)
                            .sort((a, b) => playerData.skater_performance_dict[b].game_score - playerData.skater_performance_dict[a].game_score)  // Sort by game_score in descending order
                            .map((playerId) => (
                                <option key={playerId} value={playerId}>
                                    {`${playerData.skater_performance_dict[playerId].name} — ${playerData.skater_performance_dict[playerId].game_score}`}
                                </option>
                            ))
                    }
                </select>
            </div>
            {/* Conditionally render the spinner or the matchup table */}
            {selectedPlayerId && loadingMatchupData && (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            {selectedPlayerId && !loadingMatchupData && matchupData &&
                renderMatchupTable(matchupData, selectedPlayerId, selectedPlayerGamePerformance)
            }
            <footer>
                <section className={styles.glossary}>
                    <h2>Glossary</h2>
                    <dl>
                        <dt>TOI</dt>
                        <dd>Time on ice.</dd>

                        <dt>EV TOI</dt>
                        <dd>Even strength time on ice.
                        </dd>

                        <dt>EV xGF%</dt>
                        <dd>The percentage of expected goals that were for this player's team while they were on the
                            ice at even strength.
                        </dd>

                        <dt>EV xGF</dt>
                        <dd>The total expected goals for this player's team at even strength while they were on the ice.
                        </dd>

                        <dt>EV xGA</dt>
                        <dd>The total expected goals against this player's team at even strength while they were on the
                            ice.
                        </dd>

                        <dt>xG</dt>
                        <dd>Expected goals.
                        </dd>

                        <dt>G</dt>
                        <dd>Goals.</dd>

                        <dt>A</dt>
                        <dd>Assists.</dd>

                        <dt>SOG</dt>
                        <dd>Shots on goal.</dd>

                        <dt>Blk</dt>
                        <dd>Blocked shots.</dd>

                        <dt>TO Diff</dt>
                        <dd>Turnover difference (takeaways minus giveaways).</dd>

                        <dt>FO Diff</dt>
                        <dd>Faceoff difference (wins minus losses).</dd>

                        <dt>EV DZS</dt>
                        <dd>Even strength defensive zone starts. A zone start occurs when a player's shift starts
                            on a faceoff in either the offensive or defensive zone. Players who start many shifts
                            in the defensive zone are likely more trustworthy and responsible defenders.
                        </dd>

                        <dt>EV OZS</dt>
                        <dd>Even strength offensive zone starts. A zone start occurs when a player's shift starts
                            on a faceoff in either the offensive or defensive zone. Players who start many shifts
                            in the offensive zone are likely more offensively oriented.
                        </dd>

                        <dt>Sv%</dt>
                        <dd>Save percentage. A goalie's saves divided by the total shots faced.
                        </dd>

                        <dt>xGA</dt>
                        <dd>Expected goals against. Total expected goals against a goalie while they were in the game.
                        </dd>

                        <dt>GSAx</dt>
                        <dd>Goals saved above expected. A goalie's expected goals against minus actual goals against.
                        </dd>

                        <dt>Game Score</dt>
                        <dd>A weighted score to indicate a player's individual performance in the game. Scores usually
                            range from 0-10 but can go outside of the range for abnormal performances.
                        </dd>


                    </dl>
                </section>
            </footer>
        </div>
    );
}

const renderGameScore = (game) => {
    let suffix = "";
    if (game.final_period === "OT") {
        suffix = " (OT)";
    } else if (game.final_period === "SO") {
        suffix = " (SO)";
    }
    return (
        <div className={styles.score} id="game-score">{game.winner_score + ' — ' + game.loser_score+ suffix}</div>
        );
    };

function GamePage() {
    const params = useParams();
    const gameId = params.gameId;
    const [game, setGame] = useState([]);
    const [expectedGoalsData, setExpectedGoalsData] = useState([]);
    const [playerData, setPlayerData] = useState([]);
    const [shotData, setShotData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData()
            .catch(err => {
                console.log(err);
            });
    }, []);

    const getData = async () => {
        setLoading(true);
        const cacheExpiryTime = 60 * 60 * 1000; // Cache expiration time in milliseconds (1 hour)
        const cachedData = localStorage.getItem(`cachedData-${gameId}`);
        const cacheTimestamp = localStorage.getItem(`cachedTimestamp-${gameId}`);
        const currentTime = new Date().getTime();

        if (cachedData && cacheTimestamp && (currentTime - cacheTimestamp < cacheExpiryTime)) {
            const decompressedData = JSON.parse(LZString.decompress(cachedData));
            const {gameData, expectedGoalsData, playerData, shotData} = decompressedData;
            setGame(gameData);
            setExpectedGoalsData(expectedGoalsData);
            setPlayerData(playerData);
            setShotData(shotData);
            setLoading(false);
        } else {
            await fetchData();
        }
    };

    const fetchData = async () => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        let apiUrl = `https://muffinhockey.com/api/game/${gameId}/data`;
        // console.log(apiUrl);
        const gameResp = await fetch(apiUrl);
        const gameData = await gameResp.json();
        setGame(gameData);

        apiUrl = `https://muffinhockey.com/api/game/${gameId}/xg-summary`;
        const expectedGoalsResp = await fetch(apiUrl);
        const expectedGoalsData = await expectedGoalsResp.json();
        setExpectedGoalsData(expectedGoalsData);

        setLoading(false);

        apiUrl = `https://muffinhockey.com/api/game/${gameId}/player-detail`;
        const playerDataResp = await fetch(apiUrl);
        const playerData = await playerDataResp.json();
        setPlayerData(playerData);
        // localStorage.setItem(`playerData-${gameId}`, JSON.stringify(playerData));

        apiUrl = `https://muffinhockey.com/api/game/${gameId}/shot-data`;
        const shotDataResp = await fetch(apiUrl);
        const shotData = await shotDataResp.json();
        setShotData(shotData);


        const dataToCache = {
            gameData,
            expectedGoalsData,
            playerData,
            shotData,
        };

        const clearOldCacheEntries = () => {
        // Loop through localStorage to find and delete old cached data entries
        Object.keys(localStorage).forEach(key => {
                if (key.startsWith('cachedData-') || key.startsWith('cachedTimestamp-')) {
                    localStorage.removeItem(key);
                }
            });
            console.log('Old cache entries cleared.');
        };

        try {
            const compressedData = LZString.compress(JSON.stringify(dataToCache));
            localStorage.setItem(`cachedData-${gameId}`, compressedData);
            localStorage.setItem(`cachedTimestamp-${gameId}`, new Date().getTime());
        } catch (e) {
            if (e.name === 'QuotaExceededError') {
                console.log('LocalStorage quota exceeded, clearing old cache data.');
                clearOldCacheEntries();
                // Try to store the data again after clearing old entries
                try {
                    const compressedData = LZString.compress(JSON.stringify(dataToCache));
                    localStorage.setItem(`cachedData-${gameId}`, compressedData);
                    localStorage.setItem(`cachedTimestamp-${gameId}`, new Date().getTime());
                } catch (e) {
                    console.error('Failed to store data after clearing old cache entries.');
                }
            }
        }

        // try {
        //     const compressedData = LZString.compress(JSON.stringify(dataToCache));
        //     localStorage.setItem(`cachedData-${gameId}`, compressedData);
        //     localStorage.setItem(`cachedTimestamp-${gameId}`, new Date().getTime());
        // } catch (e) {
        //     if (e.name === 'QuotaExceededError') {
        //         console.error('LocalStorage quota exceeded, clearing old cache data.');
        //         clearOldCacheEntries();
        //         // Try to store the data again after clearing old entries
        //         try {
        //             const compressedData = LZString.compress(JSON.stringify(dataToCache));
        //             localStorage.setItem(`cachedData-${gameId}`, compressedData);
        //             localStorage.setItem(`cachedTimestamp-${gameId}`, new Date().getTime());
        //         } catch (e) {
        //             console.error('Failed to store data after clearing old cache entries.');
        //         }
        //     }
        // }
        // localStorage.setItem(`cachedData-${gameId}`, JSON.stringify(dataToCache));
        // localStorage.setItem(`cachedTimestamp-${gameId}`, new Date().getTime());
        // setLoading(false);
    };

    const clearOldCacheEntries = () => {
        // Loop through localStorage to find and delete old cached data entries
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith('cachedData-') || key.startsWith('cachedTimestamp-')) {
                localStorage.removeItem(key);
            }
        });
        console.log('Old cache entries cleared.');
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return <GameRecap game={game} expectedGoalsData={expectedGoalsData} playerData={playerData} shotData={shotData}/>;
}

export default GamePage