export function getColor(value) {
    const minValue = 0;
    const maxValue = 100;
    const midValue = 50; // Midpoint value for the gradient

    const minColor = '#ea4336'; // Red
    const midColor = '#eeeeee'; // Gray
    const maxColor = '#34a853'; // Green

    let color;
    if (value <= midValue) {
        // Interpolate between red and gray
        const ratio = (value - minValue) / (midValue - minValue);
        color = interpolateColor(minColor, midColor, ratio);
    } else {
        // Interpolate between gray and green
        const ratio = (value - midValue) / (maxValue - midValue);
        color = interpolateColor(midColor, maxColor, ratio);
    }
    return color;
}
export function interpolateColor(color1, color2, ratio) {
    const [r1, g1, b1] = hexToRgb(color1);
    const [r2, g2, b2] = hexToRgb(color2);

    const r = Math.round(r1 + (r2 - r1) * ratio);
    const g = Math.round(g1 + (g2 - g1) * ratio);
    const b = Math.round(b1 + (b2 - b1) * ratio);

    return `rgb(${r}, ${g}, ${b})`;
}
export function hexToRgb(hex) {
    let r = 0, g = 0, b = 0;
    if (hex.length === 7) {
        r = parseInt(hex.slice(1, 3), 16);
        g = parseInt(hex.slice(3, 5), 16);
        b = parseInt(hex.slice(5, 7), 16);
    }
    return [r, g, b];
}