import React, {Component, useEffect, useState} from "react";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import TeamDetailPage from "./TeamPlayersPage";
import styles from '../styles/TeamSelectionPage.module.css'
import { RenderTeamList } from '../components/TeamList.js'

// function RenderTeamList(teams) {
//     return teams.map(team => (
//         // <Link className={styles.teamRows} to={`/teams/${team.abb}`} >
//         <Link key={team.abb} className={styles.teamRows} to={`/teams/${team.abb}/`}>
//             <li className="list-group-item list-group-item-action"
//                 key={team.id}
//             >
//                 <span className="me-10">
//                     <img className={styles.teamLogo}
//                          src={`https://assets.nhle.com/logos/nhl/svg/${team.abb}_light.svg`}
//                          alt={`${team.abb} logo`}/>
//                 </span>
//                 <span>
//                     {'    ' + team.location + ' ' + team.name}
//                 </span>
//             </li>
//         </Link>
//     ));
// }

function TeamsTable(teams) {
    return (
        <main className={styles.mainTable}>
            <h1 className={styles.teamTableHeading}>Select a Team to See Analytics
            </h1>
            <div className="row">
                <div className="text-center col-me-6 col-sma-10 mc-auto p-0 ">
                    <div className={`card p-3 text-center ${styles.smallWidthCentered}`}>
                        <ul className="list-group">
                            <Link key={'nhl'} className={styles.teamRows} to={`/teams/NHL/games`}>
                                <li className="list-group-item list-group-item-action"
                                    key={'nhl'}
                                >
                                    <span className="me-10">
                                        <img className={styles.nhlLogo}
                                             src={`${process.env.PUBLIC_URL}/team_logos/NHL.png`}
                                             alt={`nhl logo`}/>
                                    </span>
                                    <span>
                                        {'      ' + 'Recent' + ' ' + 'Games'}
                                    </span>
                                </li>
                            </Link>
                            {RenderTeamList(teams)}
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    );
}

function TeamSelectionPage() {
    const [teams, setTeams] = useState([])
    const [loading, setLoading] = useState(true);
    // useEffect(() => {
    //     fetch("http://localhost:8000/api/teams/")
    //         .then((res) => {
    //             return res.json()
    //         })
    //         .then(data => {
    //             setTeams(data)
    //         })
    //         .then()
    //         .catch(err => {
    //             console.log(err);
    //   });
    // }, []);
    useEffect(() => {
        getData()
            .catch(err => {
                console.log(err);
      });
    }, [])

    const getData = async () => {
        setLoading(true)
        let apiUrl =
        "https://muffinhockey.com/api/teams/"
        const teamResp = await fetch(apiUrl)
        const teamList = await teamResp.json()
        setTeams(teamList)
        setLoading(false)
    }

    if(loading) return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );

    return (
        TeamsTable(teams)
    )

}

export default TeamSelectionPage


