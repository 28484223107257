import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import styles from "../styles/HomePage.module.css";
import {getColorForGameScore, renderSkaterTable} from "./GamePage";
import {RenderGameList} from "./TeamGamesPage";


function RenderStandoutPerformances(skaters) {
  return (
    <div className={styles.tableContainer}>
      {/* Title and Subtitle */}
      <h1 className={styles.tableTitle}>Standout Performances</h1>
      {/*<p className={styles.tableSubtitle}>*/}
      {/*  Top 5 performances from around the league on most recent gameday*/}
      {/*</p>*/}

      {/* Table */}
      <table className={styles.topPerformersTable}>
        <thead>
          <tr>
            <th>Rank</th> {/* Added Rank column */}
            <th>Player</th>
            <th>Game</th>
            <th>Rating</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(skaters)
            .sort(([, a], [, b]) => b.game_rating - a.game_rating)
            .slice(0, 10) // Limit to the top 5 performers
            .map(([playerId, playerDict], index) => (
                <tr key={playerId}>
                    {/* Rank Cell */}
                    <td className={styles.indexCell}>{index + 1}</td>
                    {/* Rank starts from 1 */}

                    {/* Player Cell with mug and name */}
                    <td className={styles.playerCell}>
                        <Link to={`/players/id/${playerId}`} className={styles.playerLink}>
                            <img
                                className={styles.mug}
                                src={`https://assets.nhle.com/mugs/nhl/${playerDict.season}/${playerDict.team_abb}/${playerId}.png`}
                                alt={playerDict.name}
                            />
                            <span className={styles.playerName}
                                  style={{'--primary-color': playerDict.primary_color}}>{playerDict.name}</span>
                        </Link>
                    </td>
                    <td className={styles.gameCell}>
                        <Link to={`/games/id/${playerDict.game_id}`} className={styles.playerLink}>
                          <span className={styles.playerName}>
                            <img
                              className={styles.teamLogoMini}
                              src={`https://assets.nhle.com/logos/nhl/svg/${playerDict.team_abb}_light.svg`}
                              alt={playerDict.team_abb}
                            />
                            {playerDict.for_score} — {playerDict.ag_score}
                            <img
                              className={styles.teamLogoMini}
                              src={`https://assets.nhle.com/logos/nhl/svg/${playerDict.against_abb}_light.svg`}
                              alt={playerDict.against_abb}
                            />
                          </span>
                        </Link>
                    </td>

                    {/* Rating Cell */}
                    <td
                        className={styles.ratingCell}
                        style={{backgroundColor: getColorForGameScore(playerDict.game_rating)}}
                    >
                        {playerDict.game_rating.toFixed(2)}
                    </td>
                </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}


function HomePage() {
    const [recentData, setRecentData] = useState({});
    // const [recentGames, setRecentGames] = useState({});
    // const [surprisePerformances, setSurprisePerformances] = useState([])
    // const [powerRankings, setPowerRankings] = useState([])
    // const [gameRatingLeaders, setGameRatingLeaders] = useState([])
    // const [goalLeaders, setGoalLeaders] = useState([])
    // const [expectedGoalLeaders, setExpectedGoalLeaders] = useState([])
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        getData()
            .catch(err => {
                console.log(err);
            });
    }, [])

    const getData = async () => {
        setLoading(true);

        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months are zero-based
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const todaysDate = `${yyyy}-${mm}-${dd}`;

      let apiUrl = `https://muffinhockey.com/api/recent/${todaysDate}`;
      try {
        const recentResp = await fetch(apiUrl);
        const recentData = await recentResp.json();
        setRecentData(recentData);
      } catch (err) {
          console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

     if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.pageContainer}>
            <h1 className={styles.tableTitle}>Recent Games</h1>
            {/*<p className={styles.tableSubtitle}>*/}
            {/*    Games from most recent gameday*/}
            {/*</p>*/}
            <div className="row">
                <div className="text-center col-me-6 col-sma-10 mc-auto p-0 ">
                    <div className="card p-3 text-center">
                        <ul className="list-group">
                            <RenderGameList games={recentData.recent_games} teamAbb={"NHL"}/>
                        </ul>
                    </div>
                </div>
            </div>
            {recentData.key_performances && RenderStandoutPerformances(recentData.key_performances)}
        </div>
    );
}

export default HomePage